import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"

const Books = () => (
  <Layout> 
    <StaticQuery
    query={graphql`
    query {
      allContentfulBoek(
        sort: {fields: [title], order:DESC}
      ) {
        edges {
          node {
            title
            subtitle
            description {
              childMarkdownRemark {
                html
              }  
            }
            cover {
              title
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
    `}
    render={data=>(
      <div id="books-wrapper">
        {data.allContentfulBoek.edges.map( (item, i) => (
            <div key={i} className="book-wrapper">
              <section className="book-cover">
                <GatsbyImage image={item.node.cover.gatsbyImageData} alt={item.node.cover.title} />
              </section>
              <section className="book-info">
                <h2>
                  {item.node.title}
                </h2>
                <h3 className="book-subtitle">
                  {item.node.subtitle}
                </h3>
                <div className="book-info-content" 
                  dangerouslySetInnerHTML={{__html: item.node.description.childMarkdownRemark.html}}>
                </div>
              </section>
            </div>
          ))}
      </div>
    )}/>
  </Layout>
)

export default Books;